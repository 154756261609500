@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
.slide-fade-enter {
  opacity: 0;
  -webkit-transition: all;
  transition: all;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}
.slide-fade-enter-active {
  translate: translate(0%, 0%);
  -webkit-transition: all;
  transition: all;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
  opacity: 1;
}
.slide-fade-enter-done {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  opacity: 1;
}

.transition-item.slide-left {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.slide-left.transition-leave {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.slide-left.transition-leave.transition-leave-active {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.slide-left.transition-appear.transition-appear-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.transition-item {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: -webkit-transform 0.7s ease-in-out;
  transition: -webkit-transform 0.7s ease-in-out;
  transition: transform 0.7s ease-in-out;
  transition: transform 0.7s ease-in-out, -webkit-transform 0.7s ease-in-out;
  will-change: transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

.transition-item.slide-up,
.transition-item.slide-down,
.transition-item.slide-left,
.transition-item.slide-right {
  opacity: 1;
}

.transition-item.slide-right {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.slide-right.transition-appear-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.slide-right.transition-leave {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.slide-right.transition-leave.transition-leave-active {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.transition-item.slide-up {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.slide-up.transition-appear.transition-appear-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.slide-up.transition-leave {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.slide-up.transition-leave.transition-leave-active {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.transition-item.slide-down {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.slide-down.transition-appear.transition-appear-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.slide-down.transition-leave {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.slide-down.transition-leave.transition-leave-active {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.transition-item.fade-in-out {
  opacity: 0;
}

.fade-in-out.transition-appear.transition-appear-active {
  opacity: 1;
}

.fade-in-out.transition-leave {
  opacity: 1;
}

.fade-in-out.transition-leave.transition-leave-active {
  opacity: 0;
}

.cross-fade.transition-appear {
  opacity: 0;
}
.cross-fade.transition-appear.transition-appear-active {
  opacity: 1;
}

.cross-fade.transition-leave {
  opacity: 1;
}

.cross-fade.transition-leave.transition-leave-active {
  opacity: 0;
}

.circle-grow.transition-appear {
  -webkit-clip-path: circle(0px);
          clip-path: circle(0px);
}
.circle-grow.transition-appear.transition-appear-active {
  -webkit-clip-path: circle(100%);
          clip-path: circle(100%);
}

.circle-grow.transition-leave {
  opacity: 1;
}

.circle-grow.transition-leave.transition-leave-active {
  opacity: 1;
}

@font-face {
  font-family: "Domaine";
  font-style: italic;
  src: local("Domaine Display Italic"), url(/static/media/DomaineDisplayWeb-RegularItalic.41135885.eot) format("eot"),
    url(/static/media/DomaineDisplayWeb-RegularItalic.8e618831.woff) format("woff"),
    url(/static/media/DomaineDisplayWeb-RegularItalic.e11e52ad.woff2) format("woff2");
}

@font-face {
  font-family: "Domaine";
  font-weight: 500;
  src: local("Domaine Display Medium"), url(/static/media/DomaineDisplayWeb-Medium.067cbc26.eot) format("eot"),
    url(/static/media/DomaineDisplayWeb-Medium.147bb857.woff) format("woff"),
    url(/static/media/DomaineDisplayWeb-Medium.e5c8c5e1.woff2) format("woff2");
}

@font-face {
  font-family: "Domaine";
  src: local("Domaine Display"), url(/static/media/DomaineDisplayWeb-Regular.d153d0c4.eot) format("eot"),
    url(/static/media/DomaineDisplayWeb-Regular.24a431ba.woff) format("woff"),
    url(/static/media/DomaineDisplayWeb-Regular.6e708cf5.woff2) format("woff2");
}

html {
  scroll-behavior: smooth;
}

