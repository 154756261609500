.transition-item.slide-right {
  transform: translateX(-100%);
}

.slide-right.transition-appear-active {
  transform: translateX(0);
}

.slide-right.transition-leave {
  transform: translateX(0%);
}

.slide-right.transition-leave.transition-leave-active {
  transform: translateX(100%);
}
