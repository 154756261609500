.slide-fade-enter {
  opacity: 0;
  transition: all;
  transition-duration: 400ms;
  transition-timing-function: ease-out;
}
.slide-fade-enter-active {
  translate: translate(0%, 0%);
  transition: all;
  transition-duration: 400ms;
  transition-timing-function: ease-out;
  opacity: 1;
}
.slide-fade-enter-done {
  transform: translate(0%, 0%);
  opacity: 1;
}
