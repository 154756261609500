.transition-item.slide-left {
  transform: translateX(100%);
}

.slide-left.transition-leave {
  transform: translateX(0%);
}

.slide-left.transition-leave.transition-leave-active {
  transform: translateX(-100%);
}

.slide-left.transition-appear.transition-appear-active {
  transform: translateX(0%);
}
