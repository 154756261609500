.transition-item.slide-up {
  transform: translateY(100%);
}

.slide-up.transition-appear.transition-appear-active {
  transform: translateY(0);
}

.slide-up.transition-leave {
  transform: translateY(0);
}

.slide-up.transition-leave.transition-leave-active {
  transform: translateY(-100%);
}
