@font-face {
  font-family: "Domaine";
  font-style: italic;
  src: local("Domaine Display Italic"), url(../fonts/DomaineDisplayWeb-RegularItalic.eot) format("eot"),
    url(../fonts/DomaineDisplayWeb-RegularItalic.woff) format("woff"),
    url(../fonts/DomaineDisplayWeb-RegularItalic.woff2) format("woff2");
}

@font-face {
  font-family: "Domaine";
  font-weight: 500;
  src: local("Domaine Display Medium"), url(../fonts/DomaineDisplayWeb-Medium.eot) format("eot"),
    url(../fonts/DomaineDisplayWeb-Medium.woff) format("woff"),
    url(../fonts/DomaineDisplayWeb-Medium.woff2) format("woff2");
}

@font-face {
  font-family: "Domaine";
  src: local("Domaine Display"), url(../fonts/DomaineDisplayWeb-Regular.eot) format("eot"),
    url(../fonts/DomaineDisplayWeb-Regular.woff) format("woff"),
    url(../fonts/DomaineDisplayWeb-Regular.woff2) format("woff2");
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap");

html {
  scroll-behavior: smooth;
}
